import { http } from "@/utils/http";

// 行业洞察
export const getReNewsInFormations = (params) => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-report/alliance/newsInformation/front/openApi/v1.1/getReNewsInformations`,
    {
      params,
    },
  );
};

//招标动态
export const getLatestTender = (params) => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-report/Policy/front/openApi/V1.0/getLatestTender`,
    {
      params,
    },
  );
};
//最新政策
export const getLatestPolicy = (data) => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-report/Policy/front/openApi/all/V1.0/getLatestPolicy`,
    {
      params: data,
    },
  );
};

//首页搜索详情
export const globalSearch = (params) => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-report/homePage/homepageFrontier/front/openApi/V1.0/globalSearch`,
    {
      params,
    },
  );
};
//储能前沿搜索详情
export const getByKeyword = (params) => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-report/homePage/homepageFrontier/front/openApi/V1.0/getByKeyword`,
    {
      params,
    },
  );
};
//首页行业报告
//免费在线报告
export const getHomeOnlineReportSelected = (params) => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-report/onlineReportNew/front/openApi/v1.1/getHomeOnlineReport`,
    {
      params,
    },
  );
};

// 修改企业名称
export const updateCompanyName = (params) => {
  return http.request<{ resp_code: number; paramss: any }>(
    "get",
    `/api-user/front/v1.0/updateCompanyName`,
    {
      params,
    },
  );
};
// 查询明星企业
export const frontSelectList = () => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-industrial-map/tAd/openApi/v1.0/frontSelectList`,
    {
      data: { regionCodes: ["156"], searchBox: "", sysDictIds: [] },
    },
  );
};
// 需求大厅
export const getHomePage = () => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    "/eesa-data-center/need/need/openApi/v1.0/homePage",
  );
};

// 更新日志
export const getUpdateLogApi = () => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-data-center/system/sysUpdateLog/openApi/v1.0/getSysUpdateLog?platform=WEB`,
  );
};

// 获取需求数量
export const getNeedAmountApi = () => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-data-center/need/need/openApi/v1.0/quantityRequired`,
  );
};

// 今日储能
export const getTodayEnergyStorageApi = (params) => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-report/homePage/homepageFrontier/openApi/v1.0/energyStorageToday`,
    { params },
  );
};

// 获取热点详情
export const getHotSpotsDetailApi = (params) => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-data-center/homePage/dailyEnergyStorage/openApi/v1.0/detailsById`,
    { params },
  );
};

// 获取消息中心消息
export const getCommentListApi = (data: any) => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-data-center/comment/notice/front/v1/getNotice`,
    { data },
  );
};

// 消息全部已读
export const readCommentApi = () => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-data-center/comment/notice/front/v1/setAllRead`,
  );
};

// 消息未读数量
export const notReadNumApi = () => {
  return http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-data-center/comment/notice/front/v1/unreadCount`,
  );
};

// 设置单独已读
export const readCommentByIdApi = (data: any) => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-data-center/comment/notice/front/v1/setRead`,
    { data },
  );
};

// 远程搜索接口
export const remoteSearchApi = (_data: any) => {
  const _params = {};
  Object.assign(
    _params,
    _data.apiType === "get" ? { params: _data.data } : { data: _data.data },
  );
  return http.request<{ resp_code: number; datas: any }>(
    _data.apiType,
    _data.apiUrl,
    _params,
  );
};

// 意见信箱
export const postOpinionApi = (data: any) => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/eesa-data-center/opinion/front/v1.0/creat`,
    { data },
  );
};

// 获取意见获取功能模块
export const getOpinionModuleApi = () =>
  http.request<{ resp_code: number; datas: any }>(
    "get",
    `/eesa-data-center/opinion/openApi/v1.0/getAllModules`,
  );

// 获取下架需求列表信息
export const getOffShelfNeedApi = (params: any) =>
  http.request<{ resp_code: number; datas: any }>(
    "get",
    "/eesa-data-center/system/systemNotification/front/v1.0/getRemovalWarning",
    { params },
  );

// 需求列表已读
export const readOffShelfNeedApi = (data: any) =>
  http.request<{ resp_code: number; datas: any }>(
    "post",
    "/eesa-data-center/system/systemNotification/front/v1.0/removalWarningRead",
    { data },
  );
